<template>
  <SelectRoot v-model="computedModelValue" v-model:open="isOpen">
    <SelectTrigger
      :class="
        [
          computedSelectClassNames,
          contentClassnamesButton,
          isError ? 'is-error' : '',
        ].join(' ')
      "
      class="flex gap-x-1 items-center rounded"
    >
      <span ref="$trigger" class="inline-flex items-center gap-1">
        <template v-if="displaySelectedValue">
          <SelectValue
            :placeholder="placeholder"
            :class="[isSelect && 'text-[15px] font-medium']"
          />
          <IconChevron
            name="chevron"
            :class="[isOpen ? 'rotate-icon-up' : 'rotate-icon-down']"
            class="transition-transform"
          />
        </template>
        <template v-else>
          <slot name="trigger" />
        </template>
      </span>
    </SelectTrigger>

    <div v-if="supportText">
      <span
        :class="isError && 'text-error-red'"
        class="ps-2 text-m-sm md:text-sm text-dark-grey"
        >{{ supportText }}</span
      >
    </div>
    <SelectPortal :to="teleportContentTo || 'body'">
      <SelectContent
        class="border bg-white will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[101]"
        :class="[
          isSelect ? 'border-black py-2' : 'border-light-grey py-3',
          contentClassnamesDropdown,
        ]"
        :side-offset="sideOffset"
        :position="contentPosition"
        :style="{
          left:
            contentPosition === 'item-aligned'
              ? computedTriggerLeft + 'px'
              : undefined,
          position: contentPosition === 'item-aligned' ? 'fixed' : undefined,
          maxHeight: contentPosition === 'item-aligned' ? 'unset' : undefined,
          ...contentStyles,
        }"
      >
        <SelectViewport>
          <SelectGroup>
            <SelectItem
              v-for="(option, index) in options"
              :key="index"
              :value="option.value"
              :is-selected="option.value === computedModelValue"
              class="flex items-center space-x-2 gap-x-0 cursor-pointer text-m-medium md:text-medium outline-none hover:bg-black/10 px-6 py-3 focus:bg-black/10 transition-colors group"
            >
              <Checkbox
                v-if="!isSelect"
                :value="computedModelValue === option.value"
              />
              <SelectItemText
                :class="[
                  computedModelValue === option.value &&
                    isSelect &&
                    'font-medium',
                ]"
              >
                {{ option.label }}
              </SelectItemText>
              <IconCheckmark
                v-if="isSelect && computedModelValue === option.value"
              />
            </SelectItem>
          </SelectGroup>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>

<script setup lang="ts">
import { computed, ref, type CSSProperties, watch } from "vue";
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectItemText,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from "radix-vue";
import { IconChevron } from "@/components/atoms/Icon";
import Checkbox from "@/components/atoms/Checkbox";
import { IconCheckmark } from "@/components/atoms/Icon";
import type { Option, Size } from "@/components/atoms/Select/Select.interface";
import { useMounted, useElementVisibility, useWindowSize } from "@vueuse/core";
import { mediaBreakpointsResolver } from "@/constants/mediaBreakpointsResolver";

const props = defineProps<{
  options: Option[];
  size?: Size;
  sideOffset?: number;
  displaySelectedValue?: boolean;
  placeholder?: string;
  modelValue?: string;
  teleportContentTo?: string | HTMLElement;
  contentPosition?: "item-aligned" | "popper";
  contentStyles?: CSSProperties;
  isSelect?: boolean;
  isError?: boolean;
  supportText?: string | undefined | Ref<string>;
  contentClassnamesButton?: string;
  contentClassnamesDropdown?: string;
}>();

const emit = defineEmits(["update:modelValue", "change", "toggle"]);

const selectedValue = ref<string>(
  props.options?.find((option) => option.selected)?.value || "",
);
const $trigger = ref<HTMLElement | null>(null);
const isOpen = ref(false);
const isMounted = useMounted();

const { width: windowWidth } = useWindowSize();

const isSelectTriggerVisible = useElementVisibility($trigger);

const computedModelValue = computed<string>({
  get: () => props.modelValue || selectedValue.value,
  set: (value: string) => {
    emit("update:modelValue", value);
    emit("change", value);
    selectedValue.value = value;
  },
});

watch(isOpen, (newIsOpen) => {
  emit("toggle", newIsOpen);
});

const computedTriggerLeft = computed(() => {
  if (!isMounted.value || !$trigger.value || !isSelectTriggerVisible.value)
    return 0;

  const isWindowWidthExceedsContainerSize =
    windowWidth.value > mediaBreakpointsResolver["3xl"];
  const leftMargin = isWindowWidthExceedsContainerSize
    ? (windowWidth.value - mediaBreakpointsResolver["3xl"]) / 2
    : 0;
  return $trigger.value.getBoundingClientRect().left - leftMargin;
});

const computedSelectClassNames = computed(() => {
  switch (props.size) {
    case "small":
      return "text-m-md md:text-md";
    case "default":
    default:
      return "text-m-lg md:text-lg";
  }
});
</script>
